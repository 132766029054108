<template>
  <v-card>
    <v-card-subtitle>Propinas entre fechas</v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="8" sm="9" md="10" lg="11">
          <v-row>
            <v-col>
              <dos-fechas
                :fechas="[
                  FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                  FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
                ]"
                @getFechas="getFechas"
              ></dos-fechas>
            </v-col>
          </v-row>
        </v-col>
        <v-col  cols="4" sm="3" md="2" lg="1" class="text-right">
          <v-btn  @click="cargarDatos(filtros)" small color="primary">Buscar</v-btn>
        </v-col>

        <v-col  cols="12">
          <v-btn small @click="exportExcel()" color="success">exportar Excel</v-btn>
          <tabla-simple-reporte
          :columnas="false"
            :orientacionReporte="'l'"
            :nombreReporte="
              'Arqueo de propinas por dia ' +
              filtros.grupo_negocio.descripcion +
              ' ' +
              FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4) +
              ' al ' +
              FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4)
            "
            :cabeceraIn="cabecera"
            :datosIn="datos"
            :exportar="exportar"
            :height="400"
            :acciones="['VER']"
            @GetVer="GetVer"
          ></tabla-simple-reporte>
        </v-col>
      </v-row>
    </v-card-text>
    <ModalDetalles ref="ModalDetallesRef" />
  </v-card>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
import ModalDetalles from './componentes/ModalDetalles.vue'
// demos

export default {
  components: { TablaSimpleReporte, DosFechas, SelectGrupoNegocio, ModalDetalles },
  props: {
    filtrosShow: Boolean,
    exportar: { type: Array, default: [] },
  },
  setup() {
    const cabecera = ref([])
    const datos = ref([])
    const grupoNegocio = ref({})
    const ModalDetallesRef = ref(null)
    const filtros = ref({
      fecha: {
        desde: new Date(),
        hasta: new Date(),
      },
      id_grupo_negocio: 1,
      grupo_negocio: { descripcion: '2' },
    })
    var isNumber = function isNumber(value) {
      return typeof value === 'number' && isFinite(value)
    }
    const totalizador = () => {
      let total = {}
      cabecera.value.forEach(col => {
        datos.value.forEach(item => {
          let value = item[col.value] + (total[col.value] ? total[col.value] : 0)

          total = {
            ...total,
            [col.value]: isNumber(value) ? Number(value.toFixed(2)) : '',
          }
        })
      })
      total.nombre_mesonero = 'Total'
      console.log('totalizador')
      console.log(total)
      datos.value.push(total)
    }

    const cargarDatos = param => {
      filtros.value = param
      MaestroService.obtenerFormasPagoPropinas(param)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            console.log(response.data.columnas)
            if (response.data.datos.length > 0) {
              cabecera.value = [
                { text: 'Ver', value: 'accion' },
                { text: 'Empleado', value: 'nombre_mesonero' },
              ]
              response.data.columnas.split(',').forEach(col => {
                cabecera.value.push({ text: FuncionesGenerales.camelCase(col.replace(/_/g, ' ')), value: col })
              })
              cabecera.value.push({ text: 'Monto Total', value: 'monto_real' })
              datos.value = response.data.datos
              totalizador()
            } else {
              //  "no se econcontro resultado"
            }
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatos',
          })
        })
        .finally(() => {})
    }

    const GetVer = item => {
      ModalDetallesRef.value.abrirModal(item)
    }

    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
      //cargarDatos(filtros.value)
    }

    const getGrupoNegocio = dato => {
      grupoNegocio.value = dato
      filtros.value.id_grupo_negocio = dato.id
      filtros.value.grupo_negocio = dato
      //cargarDatos(filtros.value)
    }
    const exportExcel = () => {
      let datoEx = {
        nombreDocumento: 'Reporte propinas' + ' ' + filtros.value.fecha.desde + ' al ' + filtros.value.fecha.hasta,
        hojas: [],
      }
      datoEx.hojas.push(exportExcelExterno() )
      datoEx.hojas =  datoEx.hojas.concat(exportExcelDetalles())
      console.log("hojas",datoEx.hojas) 
       FuncionesGenerales.exportarDataExcelVariasHojas(datoEx)
    
    }
    const exportExcelExterno = () => {
      let dato = {
        nombreHoja: 'Propinas',
        cabecera: [],
        rows: [],
        anchoColumanas: [{ width: 20 }],
      }
      cabecera.value.forEach(col => {
        dato.cabecera.push(col.text)
      })

      let row = []
      datos.value.forEach(ro => {
        row = []
        cabecera.value.forEach((col, i) => {
          if (i >= 1) {
            row.push({
              v: ro[col.value],
              t: 'n',
              s: {
                numFmt: '0.00',
              },
            })
          } else {
            row.push({
              v: ro[col.value],
              t: 's',
            })
          }
        })
        dato.rows.push(row)
      })
      console.log(dato)
      return dato
    }

    const exportExcelDetalles = () => {
      let hojas = []
      datos.value.forEach(ro => {
        
        let dato = {
          nombreHoja: ro.nombre_mesonero,
          cabecera: ['COMANDA', 'FECHA', 'DES COMANDA', 'MONEDA', 'TIPO PAGO', 'MONTO MONEDA', 'MONTO $'],
          rows: [],
          anchoColumanas: [],
        }

        let row = []
        ro.detalles?.forEach(det => {
          row = [
            {
              v: det.id_comanda,
              t: 's',
            },
            {
              v: det.fecha,
              t: 's',
            },
            {
              v: det.cliente,
              t: 's',
            },
            {
              v: det.nombre_moneda,
              t: 's',
            },
            {
              v: det.nombre_tipo_pago,
              t: 'n',
              s: {
                numFmt: '0.00',
              },
            },
            {
              v: det.monto_moneda,
              t: 'n',
              s: {
                numFmt: '0.00',
              },
            },
            {
              v: det.monto_real,
              t: 'n',
              s: {
                numFmt: '0.00',
              },
            },
          ]

          dato.rows.push(row)
        })

        hojas.push(dato)
      })

      return hojas
    }

    return {
      cabecera,
      datos,
      cargarDatos,
      filtros,
      getFechas,
      FuncionesGenerales,
      getGrupoNegocio,
      exportExcelExterno,
      ModalDetallesRef,
      GetVer,
      exportExcel,
    }
  },
}
</script>
  