<template>
  <div>
    <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-text>
          <div>
            <span class="m-0 pt-6"> Mesonero: {{ datos.nombre_mesonero }} </span>
            <br />
          </div>
          <v-divider></v-divider>
          <v-row class="mb-4">
            <v-col>
              <div>
                <v-simple-table dense fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Fecha</th>
                        <th class="text-left">Desc comanda</th>
                        <th class="text-left">Moneda</th>
                        <th class="text-left">Tipo Pago</th>
                        <th class="text-right">Monto Moneda</th>
                        <th class="text-right">Monto $</th>
                        <th class="text-center">Ver</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in datos.detalles" :key="index">
                        <td>{{ item.id_comanda }}</td>
                        <td>{{ funciones.formatoFecha(item.fecha, 3) }}</td>
                        <td>{{ item.cliente }}</td>
                        <td>{{ item.nombre_moneda }}</td>
                        <td>{{ item.nombre_tipo_pago }}</td>
                        <td class="text-right">{{ funciones.formatoNumeric(item.monto_moneda) }}</td>
                        <td class="text-center">{{ funciones.formatoNumeric(item.monto_real) }}</td>
                        <td>
                          <a class="pr-2">
                            <v-icon color="primary"  @click="abrirModalComanda(item)" >{{ icons.mdiEye }}</v-icon>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ModalVerComanda
      :comanda="comandaSelect"
      :soloVer="true"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
    />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import { mdiEye } from '@mdi/js'
import funciones from '@/funciones/funciones'
import ModalVerComanda from '@/views/sistema/administracion/dashboard/componentes/ModalVerComanda.vue'
export default {
  components: {
    CabeceraEmpresa,
    ModalVerComanda
  },
  setup(props) {
    const dialog = ref(false)
    const datos = ref({})
    const comandaSelect = ref({ id: 0 })
    const abrirModal = datosIn => {
      dialog.value = true
      datos.value = datosIn
    }
    const openModalComanda = ref(false)
    const GetOpenModalComanda = item => {
      openModalComanda.value = item
    }
    const abrirModalComanda = (item)=>{
     
        comandaSelect.value = {id: item.id_comanda}
        openModalComanda.value = true
    }
    return {
      dialog,
      datos,
      icons: {
        mdiEye,
      },
      abrirModal,
      funciones,
      openModalComanda,
      GetOpenModalComanda,
      abrirModalComanda,
      comandaSelect
    }
  },
}
</script>