var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',[_vm._v("Propinas entre fechas")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"8","sm":"9","md":"10","lg":"11"}},[_c('v-row',[_c('v-col',[_c('dos-fechas',{attrs:{"fechas":[
                _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.desde, 4),
                _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.hasta, 4) ]},on:{"getFechas":_vm.getFechas}})],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4","sm":"3","md":"2","lg":"1"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.cargarDatos(_vm.filtros)}}},[_vm._v("Buscar")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v("exportar Excel")]),_c('tabla-simple-reporte',{attrs:{"columnas":false,"orientacionReporte":'l',"nombreReporte":'Arqueo de propinas por dia ' +
            _vm.filtros.grupo_negocio.descripcion +
            ' ' +
            _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.desde, 4) +
            ' al ' +
            _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.hasta, 4),"cabeceraIn":_vm.cabecera,"datosIn":_vm.datos,"exportar":_vm.exportar,"height":400,"acciones":['VER']},on:{"GetVer":_vm.GetVer}})],1)],1)],1),_c('ModalDetalles',{ref:"ModalDetallesRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }